import React, { useEffect } from 'react';
// import Navbar from '../components/_App/Navbar';
import MainBanner from '../components/HealthCoaching/MainBanner'; 
import FunFacts from '../components/HealthCoaching/FunFacts'; 
import DownloadApp from '../components/HealthCoaching/DownloadApp'; 
import LifestyleCourses from '../components/HealthCoaching/LifestyleCourses';
import ChooseProgram from '../components/HealthCoaching/ChooseProgram';
import HealthServices from '../components/HealthCoaching/HealthServices';
import AboutUs from '../components/HealthCoaching/AboutUs';
import TopSellingCourses from '../components/HealthCoaching/TopSellingCourses';
import PopularCourses from '../components/HealthCoaching/PopularCourses';
import UpcomingEvents from '../components/HealthCoaching/UpcomingEvents';
import LatestBlog from '../components/HealthCoaching/LatestBlog';
import SubscribeForm from '../components/HealthCoaching/SubscribeForm';
// import Footer from '../components/_App/Footer';
import { useRouter } from "next/router";

const Index8 = () => {
    const router = useRouter();

    useEffect(() => {
        router.push('/dashboard', undefined, { shallow: true });
    }, []);
    
    return (
        <React.Fragment>
            {/* <Navbar /> */}
            <MainBanner />
            <UpcomingEvents />
            <PopularCourses />
            {/* <LifestyleCourses /> */}
            <FunFacts />
            {/* <DownloadApp /> */}
            {/* <ChooseProgram /> */}
            {/* <HealthServices /> */}
            {/* <AboutUs /> */}
            
            {/* <Feedback /> */}
            
            {/* <LatestBlog /> */}
            {/* <SubscribeForm /> */}
            {/* <Footer /> */}
        </React.Fragment>
    )
}

export default Index8;